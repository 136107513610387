import { useAsyncEffect } from 'ahooks';
import { doc, getDoc } from 'firebase/firestore/lite';
import { useEffect, useRef, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';
import Typesense from 'typesense';
import { SearchResponse } from 'typesense/lib/Typesense/Documents';
import { db } from './firebase';

import getQrCode from './getQRCode';
import logo from './logo.svg';
import pattern from './pattern.svg';
import transformTypesenseHit from './transformTypesenseHit';

const qrCode = getQrCode({
  type: 'svg',
  height: 200,
  width: 200,
});

// displayName is expected to be format as {firstName}#{lastName}
const stringAvatar = (displayName: string) => {
  return `${displayName.split('#')[0][0]}${displayName.split('#')[1][0]}`;
};

export type InviteToken = {
  type: string;
  group: {
    title: string;
    description: string;
    photo?: {
      path: string;
      url: string;
      height: number;
      width: number;
    };
    members: {
      displayName: string;
      photoURL?: string;
      color: string;
    }[];
    members_count: number;
  };
  typesense_scoped_key: string;
  link: string;
};

function App() {
  const [inviteToken, setInviteToken] = useState<InviteToken | null>();
  const [searchParams] = useSearchParams();
  const [products, setProducts] = useState<SearchResponse<any>>();
  const inviteTokenId = searchParams.get('invite_token_id');

  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && inviteToken?.link) {
      qrCode.append(ref.current!);
      qrCode.update({
        data: inviteToken.link,
      });
    }
  }, [ref, inviteToken, products]);

  useAsyncEffect(async () => {
    if (!inviteTokenId) {
      setInviteToken(null);
      return;
    }

    const docRef = doc(db, 'invite_tokens', inviteTokenId);
    const docSnap = await getDoc(docRef);

    const res = docSnap.data();
    if (!res || res.type !== 'group') {
      setInviteToken(null);
      return;
    }

    setInviteToken(res as InviteToken);
  }, [inviteTokenId]);

  useAsyncEffect(async () => {
    if (inviteToken?.typesense_scoped_key) {
      let client = new Typesense.Client({
        nodes: [
          {
            host: '4uo2i7qrjpl8myd1p-1.a1.typesense.net',
            port: 443,
            protocol: 'https',
          },
        ],
        apiKey: inviteToken?.typesense_scoped_key,
        connectionTimeoutSeconds: 2,
      });

      const suffix =
        process.env.REACT_APP_FB_projectId === 'mygarage-prod' ? '-prod' : '';
      const data = await client
        .collections('products' + suffix)
        .documents()
        .search({ q: 'whatever', query_by: 'title' });

      setProducts(data);
    }
  }, [inviteToken?.typesense_scoped_key]);

  return (
    <div className="">
      <div className="h-screen w-screen bg-gradient-to-b from-sky-800 to-sky-600 fixed -z-20" />
      <div
        style={{ backgroundImage: `url(${pattern})` }}
        className={'h-screen w-screen bg-repeat opacity-20 fixed -z-10'}
      />
      <header className="w-screen text-white fixed top-0 bg-white bg-opacity-10 backdrop-blur-sm">
        <div className="flex items-center max-w-6xl mx-auto px-4">
          <img src={logo} className="h-10" alt="logo" />
          <h2 className="font-['Biko_Regular']">myGarage</h2>
        </div>
      </header>
      <main className="flex flex-col items-center justify-center z-10 p-6 py-20">
        {inviteToken?.group.photo?.url && (
          <div className="mb-8 animate-in fade-in duration-500">
            <img
              className="rounded-full w-32 h-32 shadow-lg object-cover"
              src={inviteToken.group.photo.url}
              alt="group"
            />
          </div>
        )}

        {inviteToken && products ? (
          <div className="px-4 py-10 sm:p-12 rounded-2xl shadow-lg  bg-white max-w-xl animate-in fade-in duration-500">
            <div className="flex flex-col items-center justify-center">
              <div className="text-2xl ">Garage partagé</div>
              <div className="text-2xl font-['Metropolis_Medium'] text-center">
                {inviteToken.group.title}
              </div>

              <div className="my-10">
                <BrowserView className="flex flex-col items-center">
                  <div className="h-auto mx-0 my-auto flex">
                    {/* <QRCode
                      size={150}
                      style={{
                        height: 'auto',
                        maxWidth: '100%',
                        width: '100%',
                      }}
                      viewBox={`0 0 150 150`}
                      value={inviteToken.link}
                    /> */}
                    <div ref={ref} />
                  </div>
                  <p className="text-center mt-3 text-sm max-w-[200px] text-[#0369a1]">
                    Scan pour télécharger l'app et rejoindre le groupe !
                  </p>
                </BrowserView>
                <MobileView>
                  <a
                    href={inviteToken.link}
                    className="bg-gradient-to-r from-[#2178A9] to-[#3290C6] text-white font-['Metropolis_Medium'] rounded-xl py-3 px-6 text-lg bg-size-200 bg-pos-0 hover:bg-pos-100 cursor-pointer my-8 hover:scale-105 hover:-translate-y-1 text-center"
                  >
                    Rejoindre le groupe
                  </a>
                </MobileView>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-sm text-gray-600 mb-2">Description</span>
                <p className="">{inviteToken.group.description}</p>
              </div>
              <div className="my-8 flex flex-col items-center">
                <span className="text-sm text-gray-600 mb-2">
                  {inviteToken.group.members_count} membre
                  {inviteToken.group.members_count > 1 ? 's' : ''}
                </span>
                <div className="flex -space-x-4">
                  {inviteToken.group.members.map(e =>
                    e.photoURL ? (
                      <img
                        className="w-12 h-12 rounded-full border border-white"
                        src={e.photoURL}
                        alt=""
                      />
                    ) : (
                      <div
                        style={{
                          backgroundColor: e.color,
                        }}
                        className="w-12 h-12 rounded-full border border-white flex items-center justify-center text-white"
                      >
                        {stringAvatar(e.displayName)}
                      </div>
                    ),
                  )}

                  {inviteToken.group.members_count > 7 ? (
                    <div className="flex justify-center items-center w-12 h-12 text-xs font-medium text-white bg-gray-700 rounded-full border-2 border-white hover:bg-gray-600 dark:border-gray-800">
                      +{inviteToken.group.members_count - 7}
                    </div>
                  ) : null}
                </div>
              </div>
              {products ? (
                <div className="flex flex-col items-center relative">
                  <span className="text-sm text-gray-600 mb-2">
                    {products.found} objets partagés
                  </span>

                  <div className="overflow-hidden relative max-h-48">
                    <div className="grid grid-cols-4 gap-1 sm:gap-2">
                      {/* <div className="grid grid-cols-4 grid-rows-2 gap-1 sm:gap-2"> */}
                      {/* @ts-ignore */}
                      {products.hits.map(({ document }: any) => {
                        const product = transformTypesenseHit(document) as any;

                        return (
                          <div className="h-24 overflow-hidden rounded-lg">
                            <img
                              alt="gallery"
                              className="block object-cover object-center h-full w-full"
                              src={product.photo.url}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div className="absolute bottom-0 h-3/4 w-full bg-gradient-to-t from-white" />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : inviteToken === null ? (
          <div className="px-4 py-10 sm:p-12 rounded-2xl shadow-lg  bg-white  max-w-xl">
            <div className="flex flex-col items-center justify-center">
              <div className="text-2xl text-center">
                Il y a un problème avec cette page
              </div>
              <a
                href="https://getmygarage.app"
                className="bg-gradient-to-r from-[#2178A9] to-[#3290C6] text-white font-['Metropolis_Medium'] rounded-xl py-3 px-6 text-lg bg-size-200 bg-pos-0 hover:bg-pos-100 cursor-pointer my-8 hover:scale-105 hover:-translate-y-1 text-center"
              >
                {/* <div className="bg-gradient-to-r from-red-100 to-red-500">Coucou</div> */}
                Revenir sur le site de myGarage
              </a>
            </div>
          </div>
        ) : null}
      </main>
    </div>
  );
}

export default App;
