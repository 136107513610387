import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getFirestore } from 'firebase/firestore/lite';
// TODO: Replace the following with your app's Firebase project configuration

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_apiKey,
  //   authDomain: 'mygarage-c5210.firebaseapp.com',
  projectId: process.env.REACT_APP_FB_projectId,
  //   storageBucket: 'mygarage-c5210.appspot.com',
  //   messagingSenderId: '133855390826',
  appId: process.env.REACT_APP_FB_appId,
  //   measurementId: 'G-1R14VR0XM1',
};

const app = initializeApp(firebaseConfig);

if (process.env.REACT_APP_FB_projectId === 'mygarage-prod') {
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
      '6LdHfGkjAAAAAAz_d9WdXHexh_z9HAJiu89rVLG9',
    ),

    isTokenAutoRefreshEnabled: true,
  });
}

export const db = getFirestore(app);
