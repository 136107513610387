import { set, toPairs } from 'lodash';

const transformTypesenseHit = <T>(o: { [key: string]: any }): T => {
  const res = toPairs(o).reduce((acc, e) => {
    set(acc, e[0], e[1]);
    return acc;
  }, {}) as T;

  return res;
};

export default transformTypesenseHit;
